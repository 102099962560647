// @ts-ignore
import Wind from "../../images/wind.svg";
// @ts-ignore
import Heart from "../../images/heart.svg";
// @ts-ignore
import Bullseye from "../../images/bullseye.svg";
// @ts-ignore
import Meditation from "../../images/meditation.png";
// @ts-ignore
import Lotus from "../../images/lotus.png";
// @ts-ignore
import Yoga from "../../images/yoga.png";
// @ts-ignore
import MilkyWay from "../../images/milky-way.png";
// @ts-ignore
import SleepyDog from "../../images/tired-dog.png";
// @ts-ignore
import PowerWash from "../../images/power-wash.png";
// @ts-ignore
import AnimalFriends from "../../images/animal-friends.png";
// @ts-ignore
import FitTogether from "../../images/fit-together.png";
// @ts-ignore
import IndicaMix from "../../images/indica-mix.png";
// @ts-ignore
import RainyMood from "../../images/rainy-mood.png";
// @ts-ignore
import Podcast from "../../images/podcast.png";
// @ts-ignore
import Calm from "../../images/calm.png"

const steps = [
    {
      number: 1,
      header: `Breathe Deep`,
      text: `Apps, tools, and guided meditations.`,
      image: Wind,
    },
    {
      number: 2,
      header: `Watch Cute Videos`,
      text: "Nothing lowers stress like interspecies friendship.",
      image: Heart,
    },
    {
      number: 3,
      header: `Set The Vibe`,
      text: "Soundscapes and podcasts to carry you through.",
      image: Bullseye,
    },
  ];
  
  const breatheTabs = [
    {
      number: 1,
      image: Meditation,
      alt: "meditation",
      header: "Breathing Tool",
      text: "The Calm app's simple tool to bring you back to your breath.",
      link: "https://www.youtube.com/watch?v=aNXKjGFUlMs",
    },
    {
      number: 2,
      image: Lotus,
      alt: "lotus",
      header: "Great Meditation",
      text: "A free YouTube channel with guided meditations for any occasion.",
      link: "https://www.youtube.com/c/GreatMeditation/featured",
    },
    {
      number: 3,
      image: Yoga,
      alt: "yoga",
      header: "Yoga With Adriene",
      text: "Gentle stretching routines for all schedules and skill levels.",
      link: "https://www.youtube.com/@yogawithadriene",
    },
    {
      number: 4,
      image: MilkyWay,
      alt: "milky way",
      header: "Scream into the Void",
      text: "For when journaling doesn't cut it.",
      link: "https://screamintothevoid.com/",
    },
  ];
  
  const watchTabs = [
    {
      number: 1,
      image: SleepyDog,
      alt: "sleepy pets",
      header: "Sleepy Pets Compilation",
      text: "Animals with zero anxiety.",
      link: "https://www.youtube.com/watch?v=EPrTHSzUPFE",
    },
    {
      number: 2,
      image: PowerWash,
      alt: "power washing supercut",
      header: "Power-Washing SuperCut",
      text: "Not cute, perhaps, but outrageously satisfying.",
      link: "https://www.youtube.com/watch?v=KG0BaEoMtgg",
    },
    {
      number: 3,
      image: AnimalFriends,
      alt: "best animal friendships",
      header: "15 Best Animal Friendships",
      text: "Lions and tigers and bears that love each other",
      link: "https://www.youtube.com/watch?v=vlWNIcf9ftI&t=109s",
    },
    {
      number: 4,
      image: FitTogether,
      alt: "fitting perfectly",
      header: "Things Fitting Perfectly Into Other Things",
      text: "Sometimes life really does work out.",
      link: "https://www.youtube.com/watch?v=Hy0Q8lipFTo",
    },
  ];

  const vibeTabs = [
    {
      number: 1,
      image: IndicaMix,
      alt: "indica-mix",
      header: "Wana's Indica Mix",
      text: "Low-key tunes to bring everything down a notch.",
      link: "https://open.spotify.com/playlist/0e3qa5JVGehbQkfP9FAnX1"
    },
    {
      number: 2,
      image: RainyMood,
      alt: "rainy mood",
      header: "Rainy Mood",
      text: "Calming rain sounds on demand.",
      link: "https://rainymood.com/"
    },
    {
      number: 3,
      image: Podcast,
      alt: "tara brach",
      header: "Tara Brach Podcast",
      text: "Guided meditations from renowned expert Tara Brach.",
      link: "https://open.spotify.com/show/37McjD0j2cdu4GExcFQgm0"
    },
    {
      number: 4,
      image: Calm,
      alt: "calm blend",
      header: "Spotify's Calm Blend",
      text: "The title says it all.",
      link: "https://open.spotify.com/genre/0JQ5IMCbQBLhoq1KyoiHEM"
    }
  ];

  export { steps, breatheTabs, watchTabs, vibeTabs };