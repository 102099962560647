import * as React from "react";
import { Box, Flex, Heading, Button, Image } from "@chakra-ui/react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { Link } from "gatsby";
//@ts-ignore
import Gummy2 from "../../images/Gummy2.png";
//@ts-ignore
import Gummy1 from "../../images/Gummy1.png";

export function QuickCalmShopNow() {
  return (
    <ParallaxProvider>
      <Box margin={"-50px 0"} padding="0 0 60px">
        <Box
          w="100%"
          h={{
            base: "450px",
            lg: "644px",
          }}
          overflow="hidden"
          position="relative"
          bgColor="#403989"
        >
          <Flex
            h={"100%"}
            w={"100%"}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading
              color="white"
              fontFamily={"Neutraface"}
              lineHeight={{ base: "54px" }}
              fontSize={{ base: "50px", lg: "120px" }}
              textAlign={{ base: "center" }}
              margin={{base: "-40px 0 0", lg: "0 auto"}}
            >
              OUT OF GUMMIES?
            </Heading>
            <Button
              as={Link}
              to="/collections/shop-all/"
              w={{ base: "200px", lg: "320px" }}
              marginTop={{ base: "30px", lg: "60px" }}
              borderRadius={"118px"}
              h={{ base: "50px", lg: "80px" }}
              bg="#44B7DC"
              color="#FFFFFF"
              fontFamily={"Neutraface"}
              fontSize={{ base: "20px", lg: "40px" }}
            >
              SHOP NOW
            </Button>
          </Flex>
        </Box>
        <Flex
          maxW={{ base: "175px", md: "160x", xl: "265px" }}
          maxH={{ base: "265px" }}
          position="absolute"
          bottom={{
            base: "320px",
            ms: "310px",
            msx: "310px",
            md: "300px",
            mds: "300px",
            lg: "300px",
            lgs: "300px",
            xl: "300px",
          }}
          right={{
            base: "0",
            msx: "20px",
            md: "20px",
            mds: "20px",
            lg: "40px",
            lgs: "60px",
            xl: "120px",
          }}
          zIndex="2"
        >
          <Parallax
            styleInner={{ transition: `6s cubic-bezier(0.34, 1.56, 0.64, 1)` }}
            y={[-120, -100]}
            tagOuter="figure"
          >
            <Image
              src={Gummy1}
              boxSize={{
                base: "130px",
                msx: "140px",
                md: "140px",
                mds: "145px",
                lg: "150px",
                lgs: "155px",
                xl: "265px",
              }}
              objectFit="contain"
            />
          </Parallax>
        </Flex>
        <Flex
          maxW={{ base: "175px", md: "160x", xl: "265px" }}
          maxH={{ base: "265px" }}
          position="absolute"
          bottom={{
            base: "-100px",
            ssm: "-80px",
            ms: "-80px",
            msx: "-80px",
            md: "-100px",
            mds: "-100px",
            lg: "-140px",
            lgs: "-140px",
            xl: "-240px",
          }}
          left={{
            base: "0",
            msx: "10px",
            md: "10px",
            mds: "20px",
            lg: "40px",
            lgs: "60px",
            xl: "90px",
          }}
          zIndex="2"
        >
          <Parallax
            styleInner={{ transition: `6s cubic-bezier(0.34, 1.56, 0.64, 1)` }}
            y={[-100, -80]}
            tagOuter="figure"
          >
            <Image
              src={Gummy2}
              boxSize={{
                base: "130px",
                msx: "140px",
                md: "140px",
                mds: "145px",
                lg: "150px",
                lgs: "155px",
                xl: "265px",
              }}
              objectFit="contain"
            />
          </Parallax>
        </Flex>
        <Box w="100vw" position="absolute" bottom={{ base: "14", lg: "0" }}>
          <svg
            width="100%"
            viewBox="0 0 1440 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1440 0V121.846H0V0C233.762 40.9043 474.347 62.2478 720 62.2478C965.653 62.2478 1206.24 40.9043 1440 0Z"
              fill="white"
            />
          </svg>
        </Box>
      </Box>
    </ParallaxProvider>
  );
}
