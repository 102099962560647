import * as React from "react";
import { Box, Text, Flex, Heading, Link, Image } from "@chakra-ui/react";
import { ResourceBox } from "./ResourceBox";
// @ts-ignore
import GummyPack from "../../images/Wana_QuickCalm_FPO_Pack 1.png";

export function QuickCalmResources() {
  return (
    <>
      <Box position="relative" w={{ base: "100vw" }}>
        <Box
          position="absolute"
          w="100%"
          top="-20"
          zIndex="40"
          textAlign={"center"}
        >
          <Flex
            flexDirection={"column"}
            borderRadius={"80px"}
            alignItems={"center"}
            justifyContent="center"
            bg="#44B7DC"
            border="3px solid #403989"
            margin="0 auto"
            w={{ base: "300px", ssm: "336px", ms: "477px", lg: "666px" }}
            h={{ base: "160px", lg: "164px" }}
          >
            <Text
              fontFamily="Bree Serif"
              color="#FFF"
              fontSize={{ base: "11px", lg: "15px" }}
              margin="0 50px 10px"
            >
              If you're in crisis, call or text 988 to reach the National
              Suicide Prevention Lifeline.
            </Text>
            <Text
              fontFamily="Bree Serif"
              color="#FFF"
              fontSize={{ base: "11px", lg: "15px" }}
              margin="0 50px"
            >
              If you need ongoing mental health services, find local and
              low-cost resources through our partners at
            </Text>
            <Link
              href="https://www.hftd.org/find-help"
              isExternal
              marginTop="10px"
              fontFamily="Neutraface"
              color="#403989"
              fontSize={{ base: "20px", lg: "24px" }}
              textDecoration={"underline"}
            >
              HOPE FOR THE DAY
            </Link>
          </Flex>
        </Box>
      </Box>
      <Box
        w="100%"
        overflow="hidden"
        position="relative"
        bg="linear-gradient(-153.43deg, #E45DA1 0%, #3C61AD 50%, #73C0B3 100%)"
        margin={{ base: "0 0 40px" }}
      >
        <ResourceBox />
        <Flex
          h={"100%"}
          w={"100%"}
          margin={{ base: "50px 0", lg: "150px auto" }}
          flexDirection={{ base: "column" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Heading
            maxW={{ base: "320px", lg: "1000px" }}
            textAlign={"center"}
            fontFamily={"Neutraface"}
            lineHeight={{ base: "50px", sm: "50px", xl: "80px" }}
            fontSize={{ base: "50px", sm: "50px", xl: "80px" }}
            color="white"
          >
            HOW TO GET THE MOST FROM YOUR GUMMIES
          </Heading>
          <Flex
            maxW="1000"
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={"center"}
            alignItems="center"
          >
            <Image
              src={GummyPack}
              alt="Quick Calm FPO Pack"
              margin={{ base: "-63px 0", lg: "0 auto" }}
              width={{ base: "220px", lg: "auto" }}
            />
            <Text
              fontFamily={"Bree Serif"}
              color="#FFFFFF"
              maxW={{ base: "85%", lg: "43%" }}
              margin={{ base: "40px auto" }}
              fontSize={{ base: "15px", lg: "20px" }}
              lineHeight={{ base: "26px", lg: "40px" }}
              textAlign={{base: "center", lg: "left"}}
            >
              Quick Calm Gummies are gentle but effective. For best results,
              start with one serving and take stock of how you feel – in both
              your mind and body – 15 minutes after consumption. If you’re still
              anxious, consider taking another (but bear in mind that multiple
              gummies may result in intoxication).
            </Text>
          </Flex>
        </Flex>
        <svg
          width="100%"
          viewBox="0 0 1440 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 15.2414C0 15.2414 132.054 -19.0517 516.797 15.2414C901.54 49.5345 1158.99 68.5977 1440 7.62643V101H0V15.2414Z"
            fill="#403989"
          />
        </svg>
      </Box>
    </>
  );
}
