import React from "react";
import { Link, Box, Image, Flex, Heading, Text } from "@chakra-ui/react";

export function ResourceCard({ card }) {
  return (
    <Box
      textAlign={"center"}
      margin="20px 0"
      border="3px solid #44B7DC"
      borderRadius={"19px"}
    >
      <Link href={card.link} isExternal>
        <Image src={card.image} alt={card.alt} borderRadius="16px" />
        <Flex
          h="100px"
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Heading
            fontFamily="Bree Serif"
            color="#44B7DC"
            fontSize="15px"
            margin="10px 0 0"
          >
            {card.header}
          </Heading>
          <Text
            maxW="200px"
            fontFamily="Bree Serif"
            color="#FFF"
            fontSize="15px"
            margin="0 auto 10px"
          >
            {card.text}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
}
