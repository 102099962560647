import { Box } from "@chakra-ui/react";
import * as React from "react";
import SeoComponent from "../components/SeoComponent";
import { QuickCalmHero } from "../sections/QuickCalmHero";
import { QuickCalmResources } from "../sections/QuickCalmResources";
import { QuickCalmShopNow } from "../sections/QuickCalmShopNow";

export default function QuickCalmPage() {
  return (
    <Box>
      <SeoComponent
        title={"Quick Calm"}
        description={"Quick Calm"}
      />
      <QuickCalmHero />
      <QuickCalmResources />
      <QuickCalmShopNow />
    </Box>
  );
}
