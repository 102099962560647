import * as React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "gatsby";

export function QuickCalmHero() {
  return (
    <>
      <Box
        w="100%"
        h={{
          base: "800px",
          sm: "800px",
          ms: "900px",
          lg: "917px",
        }}
        overflow="hidden"
        position="relative"
        style={{
          background:
            "linear-gradient(0deg, rgba(199,154,228,1) 35%, rgba(229,194,251,1) 100%)",
        }}
      >
        <Flex
          w="100%"
          h={{ base: "100%", lg: "100%", lgs: "99%" }}
          position="absolute"
          zIndex="1"
          justifyContent={{
            base: "center",
            lg: "right"
          }}
          alignItems={{ base: "end" }}
        >
          <Box w={{ base: "100%", lg: "50%" }} h={{ base: "50%", lg: "100%" }}>
            <iframe
              src="https://lottie.host/?file=28faa898-9225-48d1-b940-b35fca002b38/CcbrWd3WgL.json"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Flex>

        <Box w="100%" h="100%" position="absolute" zIndex="1">
          <Box
            w={{ base: "90%", ms: "88%", lg: "88%", xl: "80%" }}
            h="100%"
            mx={{ base: "auto" }}
            // border="1px"
          >
            <Flex
              maxW={{
                base: "1444px",
                xxl: "650px",
                sl: "750px",
                ssl: "800px",
                xsl: "900px",
                msl: "1000px",
                ml: "1050px",
                exl: "1100px",
              }}
              h="100%"
              flexDirection="column"
              justifyContent={{ base: "start", lg: "center" }}
              alignItems={{ base: "center", lg: "start" }}
              // border="1px"
            >
              <Heading
                // maxW={{
                //   base: "306px",
                //   ssm: "311px",
                //   ms: "357px",
                //   msx: "381px",
                //   md: "404px",
                //   mds: "444px",
                //   lg: "482px",
                //   lgs: "530px",
                //   xl: "571px",
                //   xxl: "100%",
                // }}
                color={"#FFFFFF"}
                fontFamily="Neutraface"
                fontSize={{
                  base: "73px",
                  sm: "68px",
                  ssm: "73px",
                  ms: "112px",
                  msx: "112px",
                  md: "112px",
                  mds: "112px",
                  lg: "140px",
                  lgs: "140px",
                  xl: "140px",
                  xxl: "160px",
                  sl: "160px",
                  ssl: "160px",
                  xsl: "160px",
                  msl: "160px",
                  ml: "160px",
                  exl: "160px",
                }}
                fontWeight={{ base: "bold" }}
                letterSpacing={{ base: "-0.88px" }}
                lineHeight={{ base: "1" }}
                textTransform="uppercase"
                noOfLines={{ base: 3 }}
                margin={{ base: "80px 0 0", lg: "0" }}
              >
                FIFTEEN <br />
                MINUTES <br />
                OF CALM
              </Heading>
              <Text
                color={"#FFFFFF"}
                fontFamily="Bree Serif"
                fontSize={{
                  base: "35px",
                  sm: "28px",
                  ssm: "35px",
                  lg: "35px"
                }}
                maxW={{
                  base: "306px",
                  ssm: "320px",
                  ms: "477px",
                  lg: "482px",
                  lgs: "530px",
                  xl: "571px",
                  xxl: "100%",
                }}
              >
                Relaxing resources to ease you into the relief of{" "}
                <Link
                  to="/products/gummies/optimals/quick-calm-rec"
                  style={{ textDecoration: "underline" }}
                >
                  Quick Calm
                </Link>
                .
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box border={"2px solid #403989"} w="100%" />
    </>
  );
}
