import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Badge,
  Link,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { ResourceCard } from "../Card";
import { steps, breatheTabs, watchTabs, vibeTabs } from "../content";

export function ResourceBox() {
  return (
    <Tabs>
      <Box margin={{base: "150px auto 0", lg: "200px auto 0"}} w={{base: "90vw"}}>
        <Box maxW="1300px" margin="auto">
          <svg
            width="100%"
            viewBox="0 0 1300 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1300 21.796C1300 14.4986 1294.8 8.34042 1287.55 7.49775C1249.16 3.03564 1117.69 -7.71377 833.447 9.16881C493.96 29.3324 264.257 40.7435 17.1707 7.00153C8.11629 5.76507 3.06608e-05 12.7741 2.51567e-05 21.9125L1.75943e-06 60.7589H1300V21.796Z"
              fill="#403989"
            />
          </svg>
        </Box>
        <Box maxW="1300px" margin={{base: "auto"}} bg="#403989">
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            maxW="1000px"
            margin="auto"
            overflow={"scroll"}
          >
            <TabList borderBottom="none" minW="1000px">
              {steps.map((step) => (
                <Flex
                  as={Tab}
                  margin={{base: "40px auto 20px", lg: "80px auto"}}
                  textAlign="left"
                  alignItems={"start"}
                  borderBottom="none"
                  key={step.number}
                >
                  <Box padding="0 10px">
                    <Badge
                      border="2px solid #44B7DC"
                      bg="transparent"
                      color="#44B7DC"
                      fontSize="22px"
                      fontFamily="Neutraface"
                      padding="5px 16.5px"
                      borderRadius="250px"
                      // marginTop="4px"
                    >
                      {step.number}
                    </Badge>
                  </Box>
                  <Box>
                    <Flex>
                      <Heading
                        maxW="70%"
                        color="#44B7DC"
                        fontSize="30px"
                        lineHeight="30px"
                        fontFamily="Neutraface"
                      >
                        {step.header}
                      </Heading>
                      <Image
                        src={step.image}
                        alt="wind"
                        objectFit="contain"
                        margin="0 10px"
                      />
                    </Flex>

                    <Text
                      maxW="300px"
                      color="#FFF"
                      fontFamily="Bree Serif"
                      fontSize="18px"
                    >
                      {step.text}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </TabList>
          </Flex>

          <TabPanels>
            {/* Tab Panel 1 */}
            <Flex
              as={TabPanel}
              justifyContent={"space-evenly"}
              alignItems="center"
              maxW="1000px"
              margin="auto"
              wrap={"wrap"}
            >
              {breatheTabs.map((card) => (
                <ResourceCard card={card} key={card.number} />
              ))}
            </Flex>

            {/* Tab Panel 2 */}
            <Flex
              as={TabPanel}
              justifyContent={"space-evenly"}
              alignItems="center"
              maxW="1000px"
              margin="auto"
              wrap={"wrap"}
            >
              {watchTabs.map((card) => (
                <ResourceCard card={card} key={card.number} />
              ))}
            </Flex>

            {/* Tab Panel 3 */}
            <Flex
              as={TabPanel}
              justifyContent={"space-evenly"}
              alignItems="center"
              maxW="1000px"
              margin="auto"
              wrap={"wrap"}
            >
              {vibeTabs.map((card) => (
                <ResourceCard card={card} key={card.number} />
              ))}
            </Flex>
          </TabPanels>
        </Box>
        <Box maxW="1300px" margin="auto">
          <svg
            width="100%"
            viewBox="0 1210 1300 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.30642e-07 1243.2C1.81769e-07 1250.5 5.20421 1256.66 12.4528 1257.5C50.8358 1261.96 182.307 1272.71 466.553 1255.83C806.04 1235.67 1035.74 1224.26 1282.83 1258C1291.88 1259.23 1300 1252.23 1300 1243.09V1204.24H1.75943e-06L4.30642e-07 1243.2Z"
              fill="#403989"
            />
          </svg>
        </Box>
      </Box>
    </Tabs>
  );
}
